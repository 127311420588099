<template>
  <div
    class="relative overflow-x-auto rounded-xl bg-white border border-gray-200"
  >
    <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
      <div class="justify-items-start p-4 text-base w-full">
        <p class="pl-2">Manage Threshold</p>
      </div>
    </div>
    <SearchField
      @input="searchPractices"
      placeholder="Search Practice"
      class="border-t border-gray-100"
    />
    <table class="w-full text-sm text-left">
      <thead class="text-sm text-white bg-teal border-b">
        <tr>
          <th scope="col" class="pl-6 py-4 tracking-wider">
            <span v-for="header in tableHeadings" :key="header">{{
              header
            }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in thresholdData?.data" :key="data?.id">
          <div class="border-b border-gray-200">
            <td
              class="py-4 font-medium text-gray-900 overflow-hidden items-center"
            >
              <div>
                <div class="flex items-center">
                  <button @click="togglePractices(data?.id)" class="pl-6 pr-2">
                    <span class="material-icons">{{
                      data?.showShifts
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_up"
                    }}</span>
                  </button>
                  <div class="py-4">{{ data?.practice_name }}</div>
                </div>
              </div>
            </td>
          </div>
          <div
            v-if="data?.showShifts"
            class="px-4 question-table-bg border-b text-gray-500"
          >
            <div class="flex items-center">
              <button @click="toggleMidDay(data?.id)" class="pl-6 pr-2">
                <span class="material-icons">{{
                  data?.showMidDay ? "keyboard_arrow_down" : "keyboard_arrow_up"
                }}</span>
              </button>
              <div class="py-4">Mid-Day 11:00 AM</div>
            </div>
          </div>
          <div v-if="data?.showMidDay" class="question-table-bg">
            <table class="w-full text-sm text-left">
              <thead class="text-sm text-black">
                <tr>
                  <th
                    scope="col"
                    class="px-16 py-4 tracking-wider border-r border-gray-200"
                    :class="{
                      'flex justify-center': index === 1,
                    }"
                    v-for="(header, index) in questionHeadings"
                    :key="header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(threshold, index) in data?.thresholdsMidDay"
                  :key="index"
                  class="border-t border-gray-200 text-gray-500"
                >
                  <td
                    class="py-4 px-5 font-medium overflow-hidden items-center border-r border-gray-200"
                  >
                    <div class="px-12 py-2">{{ threshold?.label }}</div>
                  </td>
                  <td
                    class="px-6 py-6 font-medium flex justify-center text-sm text-teal"
                  >
                    <input
                      min="0"
                      max="999"
                      type="number"
                      step="1"
                      oninput="validity.valid||(value='');"
                      @wheel.prevent=""
                      v-model="threshold.threshold"
                      @input="midDayAccept(data?.id)"
                      class="pl-2 rounded-md border border-teal w-10 focus:border-teal"
                    />
                  </td>
                </tr>
                <tr class="border-t border-gray-200 text-gray-500">
                  <td
                    class="py-4 font-medium overflow-hidden items-center border-r border-gray-200"
                  >
                    <div class="px-6 py-2"></div>
                  </td>
                  <td class="px-6 py-6 font-medium flex justify-center text-sm">
                    <div
                      class="px-3 rounded-md border text-white cursor-pointer"
                      @click="updateThreshold(data?.thresholdsMidDay, data?.id)"
                      :class="
                        data?.midDayAcceptFlag
                          ? 'border-teal bg-teal'
                          : 'border-gray-500 bg-gray-500 pointer-events-none'
                      "
                    >
                      Approve
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="data?.showShifts"
            class="px-4 question-table-bg border-b text-gray-500"
            :class="{ 'border-t text-gray-500': data?.showMidDay }"
          >
            <div class="flex items-center">
              <button @click="toggleEvening(data?.id)" class="pl-6 pr-2">
                <span class="material-icons">{{
                  data?.showEvening
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_up"
                }}</span>
              </button>
              <div class="py-4">Evening 4:00 PM</div>
            </div>
          </div>
          <div v-if="data?.showEvening" class="question-table-bg">
            <table class="w-full text-sm text-left">
              <thead class="text-sm text-black">
                <tr>
                  <th
                    scope="col"
                    class="px-16 py-4 tracking-wider border-r border-gray-200"
                    :class="{
                      'flex justify-center': index === 1,
                    }"
                    v-for="(header, index) in questionHeadings"
                    :key="header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(threshold, index) in data?.thresholdsEvening"
                  :key="index"
                  class="border-t border-gray-200 text-gray-500"
                >
                  <td
                    class="py-4 px-5 font-medium overflow-hidden items-center border-r border-gray-200"
                  >
                    <div class="px-12 py-2">{{ threshold?.label }}</div>
                  </td>
                  <td
                    class="px-6 py-6 font-medium flex justify-center text-sm text-teal"
                  >
                    <input
                      min="0"
                      max="999"
                      type="number"
                      step="1"
                      oninput="validity.valid||(value='');"
                      @wheel.prevent=""
                      v-model="threshold.threshold"
                      @input="eveningAccept(data?.id)"
                      class="pl-2 rounded-md border border-teal w-10 focus:border-teal"
                    />
                  </td>
                </tr>
                <tr class="border-t border-gray-200 text-gray-500">
                  <td
                    class="py-4 font-medium overflow-hidden items-center border-r border-gray-200"
                  >
                    <div class="px-6 py-2"></div>
                  </td>
                  <td class="px-6 py-6 font-medium flex justify-center text-sm">
                    <div
                      class="px-3 rounded-md border text-white cursor-pointer"
                      @click="
                        updateThreshold(data?.thresholdsEvening, data?.id)
                      "
                      :class="
                        data?.eveningAcceptFlag
                          ? 'border-teal bg-teal'
                          : 'border-gray-500 bg-gray-500 pointer-events-none'
                      "
                    >
                      Approve
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="thresholdData?.current_page"
      :totalPages="thresholdData.last_page"
      @page-changed="fetchPage"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import { useStore } from "vuex";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import SearchField from "@/components/newUi/baseComponents/SearchField.vue";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";

const store = useStore();
const toast = inject("toast");

const thresholdData = ref({});
const tableHeadings = ["Practices List"];
const questionHeadings = ref(["Question", "Threshold"]);

onMounted(async () => {
  const response = await store.dispatch("hqModelDay/fetchThresholds", {
    page: 1,
  });
  thresholdData.value = response;
  divideThresholds();
});

const divideThresholds = () => {
  for (let i = 0; i < thresholdData.value?.data.length; i++) {
    for (let j = 0; j < thresholdData.value?.data[i]?.thresholds.length; j++) {
      if (thresholdData.value?.data[i]?.thresholds[j]?.shift === 2) {
        // Initialize thresholdsMidDay array if it does not exist
        if (!thresholdData.value.data[i].thresholdsMidDay) {
          thresholdData.value.data[i].thresholdsMidDay = [];
        }
        thresholdData.value.data[i].thresholdsMidDay.push(
          thresholdData.value.data[i].thresholds[j]
        );
      } else if (thresholdData.value?.data[i]?.thresholds[j]?.shift === 3) {
        // Initialize thresholdsEvening array if it does not exist
        if (!thresholdData.value.data[i].thresholdsEvening) {
          thresholdData.value.data[i].thresholdsEvening = [];
        }
        thresholdData.value.data[i].thresholdsEvening.push(
          thresholdData.value.data[i].thresholds[j]
        );
      }
    }
  }
};

const togglePractices = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showShifts = !rowData.showShifts;
    rowData.showMidDay = 0;
    rowData.showEvening = 0;
  }
};
const toggleMidDay = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showMidDay = !rowData.showMidDay;
    rowData.showEvening = 0;
  }
};
const toggleEvening = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showEvening = !rowData.showEvening;
    rowData.showMidDay = 0;
  }
};
const fetchPage = async (page) => {
  const response = await store.dispatch("hqModelDay/fetchThresholds", {
    page: page,
  });
  thresholdData.value = response;
  divideThresholds();
};
const searchPractices = (searchText) => {
  console.log(searchText);
};
const updateThreshold = async (thresholdList, id) => {
  const payload = {
    practice: null,
    shift: null,
    thresholds: [],
  };
  let error = false;
  if (thresholdList[0]) {
    payload.practice = thresholdList[0].practice;
    payload.shift = thresholdList[0].shift;
  }
  if (thresholdList) {
    thresholdList.forEach((threshold) => {
      if (
        threshold.threshold === "" ||
        threshold.threshold < 0 ||
        threshold.threshold > 999
      ) {
        toast.show("Please add valid threshold between 0 - 999");
        error = true;
        return;
      }
    });
    thresholdList.forEach((threshold) => {
      const thresholObject = {};
      thresholObject.id = threshold.id;
      thresholObject.threshold = threshold.threshold;
      thresholObject.label = threshold.label;
      payload.thresholds.push(thresholObject);
    });
  }
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.midDayAcceptFlag = 0;
    rowData.eveningAcceptFlag = 0;
  }
  if (!error) {
    const response = await store.dispatch(
      "hqModelDay/updateThresholds",
      payload
    );
    if (response?.data?.success) {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Threshold(s) updated successfully",
        iconHtml: `<img src="${icon}" />`,
        width: "32em",
        customClass: {
          title: "popup-title",
          popup: "popup-border",
          content: "popup-text",
          icon: "popup-icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
    }
  }
};
const midDayAccept = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.midDayAcceptFlag = 1;
    rowData.eveningAcceptFlag = 0;
  }
};
const eveningAccept = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.eveningAcceptFlag = 1;
    rowData.midDayAcceptFlag = 0;
  }
};
</script>

<style scoped>
.question-table-bg {
  background-color: #f1fcfbb8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
